import { useState } from "react";

export const hash = (data: any): number => {
  let dataStr = data.toString();
  let hashVal: number = 0;
  let chr: number = 0;

  for (let i = 0; i < dataStr.length; i++) {
    chr = dataStr.charCodeAt(i);
    hashVal = (hashVal << 5) - hashVal + chr;
    hashVal |= 0; // Convert to 32bit integer
  }
  return hashVal;
};

export function capitalizeFirstLetter(aString: string): string {
  if (typeof aString === "string") {
    return aString.charAt(0).toUpperCase() + aString.slice(1);
  } else {
    // if it is not a string, pass it through so we don't trigger extra errors
    return aString;
  }
}

export function capitalizeByWord(aString: string): string {
  if (typeof aString === "string") {
    return aString
      .toLocaleLowerCase()
      .replace(/(?:^|\s|["'([{])+\S/g, (c) => c.toUpperCase());
  } else {
    // if it is not a string, pass it through so we don't trigger extra errors
    return aString;
  }
}

export function snakeCase(str: string, preserveCase: boolean = false): string {
  if (typeof str === "string") {
    const base = preserveCase ? str : str.toLocaleLowerCase();
    return base.replace(/ /g, "_");
  }
  return str;
}

export function snakeToLowercase(str: string): string {
  if (typeof str === "string") {
    return str.toLocaleLowerCase().replace(/_/g, " ");
  }
  return str;
}

export function equalCaseInsensitive(
  a: string,
  b: string,
  trim: boolean = false
): boolean {
  const maybeTrim = trim ? (s: string) => s.trim() : (s: string) => s;
  return (
    (typeof a === "string" ? maybeTrim(a).toLocaleLowerCase() : a) ===
    (typeof b === "string" ? maybeTrim(b).toLocaleLowerCase() : b)
  );
}

export function shortOrdinal(num: number): string {
  if (Math.floor(num / 10) % 10 === 1) {
    return `${num}th`;
  }
  switch (num % 10) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
}

export function NullsToBottom<I>(
  a: I,
  b: I,
  valueOrCallback: number | ((a: I, b: I) => number)
): number {
  if (a === null || a === undefined) {
    return -1;
  }
  if (b === null || b === undefined) {
    return 1;
  }
  if (typeof valueOrCallback === "function") {
    return valueOrCallback(a, b);
  }
  return valueOrCallback;
}

export function cloneWithIndicesAsValues<T>(
  obj: T
): { [k: string]: number } | T {
  if (typeof obj !== "object") return obj;
  return Object.fromEntries(Object.keys(obj as object).map((k, i) => [k, i]));
}

export function dot<O>(
  path: string,
  object: any,
  missing: O | undefined = undefined
) {
  let parts = path.split(".");
  for (let part of parts) {
    if (typeof object === "object" && object !== null && part in object) {
      object = object?.[part];
    } else {
      return missing;
    }
  }
  return object;
}
export function dotSet(path: string, object: any, value: any): boolean {
  let parts = path.split(".");
  for (let part of parts.slice(0, -1)) {
    if (typeof object === "object" && object !== null && part in object) {
      object = object?.[part];
    } else {
      return false;
    }
  }
  if (typeof object === "object" && object !== null) {
    object[parts[parts.length - 1]] = value;
    return true;
  } else {
    return false;
  }
}

export function isValidDate(d: Date | any): boolean {
  return d instanceof Date && !Number.isNaN(d.valueOf());
}

export function parseSemver(s: string): Array<number | string> {
  const annotationSeparator: number = s.search(/-|\+/);
  const versionCore: Array<number | string> = (
    annotationSeparator > -1 ? s.slice(0, annotationSeparator) : s
  )
    .split(".")
    .map((n) => Number.parseInt(n, 10));
  if (annotationSeparator > -1) {
    return versionCore.concat(s.slice(annotationSeparator));
  }
  return versionCore;
}

export function compareSemver(a: string, b: string): number {
  const aParsed = parseSemver(a);
  const bParsed = parseSemver(b);
  for (let i = 0; i < 3; i++) {
    let diff = +aParsed[i] - +bParsed[i];
    if (diff !== 0) return diff;
  }
  // we don't have a canonical way to compare any build / prerelease labels,
  // so at this point just consider them equal
  return 0;
}

// "Reverse LookUp" helper -- quick method to swap keys and values
// This is only meant as a holdover until we have Typescript and can use better
// structures like reversible enums or just decent definition classes.
export function rlu(obj: any): any {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));
}

// quick random ID with no guarantees about collisions
// TODO: upgrade to UUID
const BASE_32 = "0123456789abcdefhjkmnpqrstuvwxyz";
export const BASE_32_NUM_BIASED = "01234567890123456789abcdefhjkmnpqrstuvwxyz";
export function randomId(size: number, alphabet: string = BASE_32_NUM_BIASED) {
  let arr = new Array(size);
  return arr
    .fill("")
    .map((_) => alphabet[Math.floor(Math.random() * alphabet.length)])
    .join("")
    .toLocaleUpperCase();
}

/**
 * Generate a pair of hex bytes within a given range
 */
export function randHexPair(min: number = 0, max: number = 256): string {
  return (Math.floor(Math.random() * (max - min)) + min).toString(16);
}

export interface CountingSet<T> {
  add: (i: T) => void;
  get: (i: T) => number;
  counts: () => { [k: string]: number };
}
export function CountingSet<T>(): CountingSet<T> {
  const map = new Map<T, number>();
  return {
    add: (item: T) => {
      if (map.has(item)) {
        map.set(item, map.get(item) ?? 0 + 1);
      } else {
        map.set(item, 1);
      }
    },
    get: (item: T) => {
      return map.get(item) ?? 0;
    },
    counts: () => Object.fromEntries(map.entries()),
  };
}

export function _switch<V, S>(
  value: V,
  ...cases: Array<V | ((v: V) => boolean) | S>
): S | undefined {
  // if (cases.length % 2 !== 0) throw new Error("_switch must have odd number of arguments!");
  for (let i = 0; i < cases.length - 1; i += 2) {
    if (typeof cases[i] == "function") {
      // if the case is a function, we run it and use the return value:
      if ((cases[i] as (v: V) => boolean)(value)) {
        return cases[i + 1] as S;
      }
    } else {
      // otherwise we just compare with the value
      // TODO: should we have support for shallow comparisons of, say, arrays?
      if (cases[i] === value) {
        return cases[i + 1] as S;
      }
    }
  }
  // we didn't find a match, if we have an "extra" arg we treat it as the default
  if (cases.length % 2 !== 0) return cases[cases.length - 1] as S;
  // otherwise leave it undefined
  return undefined;
}

/**
 * Add an item to an array at an index or at the first instance matching a
 * given predicate function.
 * @param {any} newItem item to be inserted
 * @param {*} arr array to insert item into
 * @param {any | (any, number) => boolean} indexOrCallback either a predicate
 *        function to run on array elements or an index in the array
 * @returns any[]
 */
export function spliceIntoAt<T>(
  newItem: T,
  arr: T[],
  indexOrCallback: number | ((v: T) => boolean)
): T[] {
  if (!Array.isArray(arr)) {
    if (arr === null || arr === undefined) {
      return [newItem];
    }
    throw new Error("Splice target was not an array!");
  }
  if (typeof indexOrCallback === "function") {
    indexOrCallback = arr.findIndex(indexOrCallback);
  }
  if (indexOrCallback === -1) {
    return [...arr, newItem];
  }
  return [
    ...arr.slice(0, indexOrCallback),
    newItem,
    ...arr.slice(indexOrCallback + 1),
  ];
}

/**
 * Remove an item, if present, from an array, returning a new array object
 * @param {any} toRemove Item to be removed or callback to run
 * @param {any[]} arr Array to be removed from
 * @returns any[] new array without target item
 */
export function spliceOutOf<T>(
  toRemoveOrCallback: ((v: T) => boolean) | T,
  arr: T[]
): T[] {
  if (!Array.isArray(arr)) return [];
  let i: number;
  if (typeof toRemoveOrCallback === "function") {
    i = arr.findIndex(toRemoveOrCallback as (v: T) => boolean);
  } else {
    i = arr.indexOf(toRemoveOrCallback);
  }
  let out = arr.slice();
  if (i > -1) {
    out.splice(i, 1);
  }
  return out;
}

export function cleaveBefore<T>(arr: T[], index: number): [T[], T[]] {
  let left = Array.from(arr);
  let right = left.splice(index, arr.length - index);
  return [left, right];
}

export function deltaArrays<T>(old: T[], nu: T[]): [T[], T[], T[]] {
  const oldSet = new Set(old);
  const newSet = new Set(nu);
  return [
    nu.filter((n) => !oldSet.has(n)),
    old.filter((o) => !newSet.has(o)),
    old.filter((o) => newSet.has(o)),
  ];
}

export function adjacentPairs<T>(arr: T[], pairForLast: T): Array<[T, T]> {
  return arr
    .filter((x, i) => i % 2 === 0)
    .map((left, i) => [
      left,
      2 * i + 1 >= arr.length ? pairForLast : arr[2 * i + 1],
    ]);
}

export function ordinals(n: number): number[] {
  if (n < 1) return [];
  return [...Array(n).keys()];
}

export function positiveModulo(x: number, m: number): number {
  return ((x % m) + m) % m;
}

export function wraparoundAccess<T>(arr: T[], i: number): T {
  return arr[positiveModulo(i, arr.length)];
}

// export function joinJSX (arr, separator) {
//   return arr.map((x, i) => <Fragment key={i}>{x}{i < arr.length - 1 ? separator : null}</Fragment>)
// }

export function debounce<T>(
  fn: (...args: T[]) => void,
  time: number
): (...args: T[]) => void {
  let isFirst = true;
  let lastCall = null;
  let emitNeeded = false;
  let waitingArgs: T[] = [];
  let timerId = null;
  let trailing = () => {
    if (emitNeeded) {
      fn(...waitingArgs);
      emitNeeded = false;
      waitingArgs = [];
      timerId = window.setTimeout(trailing, time);
    } else {
      // nothing was left, so we've waited long enough to clear
      lastCall = null;
      isFirst = true;
    }
  };

  let leading = (...incomingArgs: T[]) => {
    lastCall = Date.now();
    if (isFirst) {
      // first call in this window, we emit on the leading edge
      isFirst = false;
      fn(...incomingArgs);
      timerId = window.setTimeout(trailing, time);
    } else {
      emitNeeded = true;
      waitingArgs = incomingArgs;
    }
  };

  // let cancel = () => {
  //   window.clearTimeout(timerId);
  //   lastCall = null;
  //   isFirst = true;
  //   emitNeeded = false;
  //   let timerId = null;
  // };

  return leading;
}

export function isObject(x: any, excludeArrays: boolean = false): boolean {
  return (
    typeof x === "object" && x !== null && !(excludeArrays && Array.isArray(x))
  );
}

export function assignOnly<S extends object, T extends object>(
  source: S,
  target: T,
  propertyList: Array<keyof T & keyof S>,
  skipUndefined: boolean = false
): T {
  for (let prop of propertyList) {
    let defaultValue = undefined;
    if (Array.isArray(prop)) {
      if (prop.length !== 2)
        throw new Error(`Props should be strings or tuples of key,default`);
      [prop, defaultValue] = prop;
    }
    let toAssign = prop in source ? source[prop] : defaultValue;
    if (skipUndefined && toAssign === undefined) continue;
    target[prop] = toAssign;
  }
  return target;
}

export function deepAssignMulti(
  objectHandler: (items: [object, ...any]) => any,
  ...items: any[]
): any {
  if (items.length === 0) {
    throw new Error("Deep assign needs at least 1 object");
  }
  // we always use the first item as the canonical type
  if (Array.isArray(items[0])) {
    // ARRAY
    // TODO: do we need to provide an option for merged arrays?
    return items[0].map((_, key) => {
      let innersAtKey = items
        .map((i) => i?.[key])
        .filter((i) => i !== undefined && i !== null);
      if (innersAtKey.length === 0) return undefined;
      return deepAssignMulti(objectHandler, ...innersAtKey);
    });
  } else if (typeof items[0] === "object" && items[0] !== null) {
    // OBJECT
    if (objectHandler) {
      const result = objectHandler(items as [object, ...any]);
      if (result !== undefined) {
        return result;
      }
    }
    let keys = new Set();
    // we iterate starting at the last item because we want to preserve the
    // oldest present order
    for (let i = items.length - 1; i >= 0; i--) {
      if (typeof items[i] === "object" && items[i] !== null) {
        Object.keys(items[i]).forEach((key) => keys.add(key));
      } else {
        console.warn(`skipping non-object item in slot ${i}: ${items[i]}`);
      }
    }
    // NOTE: JS Sets *do* iterate over their elements in insertion order!
    return Object.fromEntries(
      Array.from(keys).map((key) => {
        let innersAtKey = items
          .map((i) => i?.[key])
          .filter((i) => i !== undefined && i !== null);
        if (innersAtKey.length === 0) return undefined;
        return [key, deepAssignMulti(objectHandler, ...innersAtKey)];
      })
    );
  } else {
    // VALUE
    return items[0];
  }
}

export function pluralizeUnit(
  item: any,
  unit: string,
  customPlural: string,
  numberReplacements: { [k: number]: string } = {},
  customNullishMessage?: string
) {
  let count;
  if (Number.isFinite(item)) {
    count = item;
  } else if (Array.isArray(item)) {
    count = item.length;
  } else if (item instanceof Map || item instanceof Set) {
    count = item.size;
  } else if (typeof item === "object" && item !== null) {
    count = Object.keys(item).length;
  } else {
    if (customNullishMessage) return customNullishMessage;
    count = 0;
  }
  return `${count in numberReplacements ? numberReplacements[count] : count} ${
    count !== 1 ? customPlural || unit + "s" : unit
  }`;
}

export function clamp(x: number, a: number, b: number): number {
  return x < a ? a : x > b ? b : x;
}

export function objectEntries(maybeObj: any) {
  return isObject(maybeObj) ? Object.entries(maybeObj) : [];
}

export function chunkedBy<T>(arr: T[], chunkSize: number): T[][] {
  if (chunkSize > arr.length) return [arr.slice()];
  const chunked = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunked.push(arr.slice(i, i + chunkSize));
  }
  return chunked;
}

export function deepCopy(obj: any): any {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error("Cannot deepCopy, object is not JSONable", obj);
    if (Array.isArray(obj)) return [];
    return {};
  }
}

export function safeStringify(obj: any, padding: number = 2, omitKeys: string[] = []): string {
  try {
    const seen = new WeakMap();
    return JSON.stringify(
      obj,
      (k, v) => {
        if (omitKeys.includes(k)) {
          return undefined;
        }
        if (typeof v === "object" && v !== null) {
          if (seen.has(v)) {
            return `«repeat object; last key was '${seen.get(v)}'»`;
          }
          seen.set(v, k);
        } else if (typeof v === "function") {
          return `«function: ${v?.name}»`;
        }
        return v;
      },
      padding
    );
  } catch (e) {
    return `STRINGIFY ERROR: ${e}`;
  }
}

export function asArrayWhenScalar<T>(arg: T|T[]): T[] {
  if (Array.isArray(arg)) {
    return arg;
  } else {
    return [arg];
  }
}

export function isInArrayRange<T extends any[]>(
  arr: T,
  index: any
): index is number & keyof T {
  return Number.isInteger(index) && index >= 0 && index < arr.length;
}

export function removeNewlines(
  str: string,
  collapseSpaces: boolean = true
): string {
  if (collapseSpaces) {
    return str.replace(/(\s?)\s*\n\s*/g, "$1");
  } else {
    return str.replace(/\n/g, "");
  }
}
export function oneline(strings: TemplateStringsArray, ...keys: any[]): string {
  let final = "";
  for (let i = 0; i < keys.length; i++) {
    final += `${strings[i]}${keys[i]}`;
  }
  final += strings[strings.length - 1];
  return removeNewlines(final);
}

export function joinWithConjunction (arr: any[], sep: string = ",", conj: string = "&", oxford: boolean = false) {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return `${arr[0]} ${conj} ${arr[1]}`;
  return `${arr.slice(0, -1).join(sep + " ")}${oxford ? sep : ""} ${conj} ${arr[arr.length - 1]}`;
}