import { PseudoMeasurementQuestionDefinition, QuestionDefinition } from "./questions";

// We may get more specific later...
export type UnitLabel = string;

export const METRIC = "metric";
export const IMPERIAL = "us";
export enum SystemOfMeasure {
  Metric = METRIC,
  Imperial = IMPERIAL,
  Nonstandard = "nonstandard"
};

export const ESTABLISHED_SYSTEMS = [SystemOfMeasure.Imperial, SystemOfMeasure.Metric];

export interface AtomicUnit {
  isProviderPreferred?: boolean;
  isTuple?: false;
  unit: UnitLabel;
  unitTranslationIds?: [string, string],
  unitLong: string;
  default?: number;
  conversionFactor: number;
  max?: number;
  min?: number;
  listExhaustively?: boolean;
};

export interface SubdividedUnit {
  isProviderPreferred?: boolean;
  isTuple: true;
  unit: UnitLabel[];
  unitTranslationIds?: Array<[string, string]>,
  unitLong: string[];
  default?: number[];
  conversionFactor: number[];
  max?: number[];
  min?: number[];
  listExhaustively?: boolean[];
};

export type Unit = AtomicUnit | SubdividedUnit;

export function toAtomArray (unit : Unit) : AtomicUnit[] {
  if (unit.isTuple) {
    return unit.unit.map((unitLabel, i) => {
      return ({
        unit: unitLabel,
        unitLong: unit.unitLong?.[i],
        max: unit.max?.[i],
        min: unit.min?.[i],
        listExhaustively: unit.listExhaustively?.[i],
        conversionFactor: unit.conversionFactor?.[i],
        unitTranslationIds: unit.unitTranslationIds?.[i]
      });
    })
  } else {
    return [{...unit}];
  }
}

export type Dimension = {
  [METRIC]?: Unit,
  [IMPERIAL]?: Unit,
  [SystemOfMeasure.Nonstandard]?: Unit
}

export interface NumberAndUnitType {
  value: number | null;
  unit: UnitLabel;
  index: number;
};

export function pseudoMeasurementUnit (qd: PseudoMeasurementQuestionDefinition): AtomicUnit {
  return ({
    isTuple: false,
    unit: qd.unit,
    unitLong: qd.unitLong ?? qd.unit,
    unitTranslationIds: [qd.unitLongTranslationId ?? qd.unitTranslationId ?? "--", qd.unitTranslationId ?? "--"],
    default: qd.initialValue ?? 0,
    max: qd.max ?? 100,
    min: qd.min ?? 0,
    listExhaustively: true,
    conversionFactor: 1.0
  });
}