import { AnatomicalModel, ClickResolutionMethod, SVGRegionSelectorType } from "../models/anatomical-models";

/**
 * Whether the app should draw connecting lines to ALL regions, regardless of
 * whether they are selected. These lines will be rendered partially trasparent.
 */
export const USE_SEMITRANSPARENT_LINES = true;
/**
 * Whether the app should fade out non-selected choices in the legend (once at
 * least one choice other than the current view's overall is chosen). Otherwise
 * they will all be rendered completely opaque.
 */
export const USE_SEMITRANSPARENT_CHOICES = false;
/**
 * Whether the view's image should attempt to embed an SVG image as a native
 * elemen t of the page (as opposed to the src of an <img> tag or other use that
 * treats it as a separate document). This requires some trickery with webpack,
 * see DynamicSVG for more details, but allows us to do runtime JS interactions
 * with the SVG itself slash apply CSS to its children.
 * 
 * NOTE: At this point, turning this off will break a number of features!!!
 */
export const USE_DYNAMIC_SVG_EMBED = true;
/**
 * Whether the app should attempt to display region highlighting by modifying
 * the CSS of nodes within the SVG. This does nothing when USE_DYNAMIC_SVG_EMBED
 * is off, and has further configuration at SVG_SELECTION_TYPE.
 */
export const HIGHLIGHT_BY_SVG_NODES = true;
/**
 * Whether a model/view's coordinate pairs should be treated as having their
 * origin at the top left of the SVG element, or if they should be relative to
 * the box that handled the click event.
 * 
 * This setting is mostly irrelevant since now all click events are handled by
 * the overall image, so the settings are 99% equivalent.
 */
export const POINTS_ZEROED_TO_SVG_ROOT = true;

// unsure about this usage, I believe it has been phased out
export const DEFAULT_REGION_DEF = [];

export const SVG_SELECTION_TYPE : SVGRegionSelectorType =
  SVGRegionSelectorType.GroupIdMatchesRegionHighlightId;

/**
 * The previous set up before our interaction model was simplified. Typed to
 * any because its views haven't been updated to be compliant with API changes.
 */
export const DEFAULT_HUMAN_MODEL_V1 : any = {};

const DEFAULT_HUMAN_MODEL_V2 : AnatomicalModel = {
  "body front": {
    "isRoot": true,
    "isLeaf": false,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_full body_{SEX}.svg",
    "choices": [
      {
        "viewId": "head",
        "label": "Head",
        "labelTranslationId": "R_ANT025",
        "highlightRegion": "Head",
        "lineAnchor": {f: [496, 105], m: [506, 54]}
      },
      {
        "viewId": "chest",
        "label": "Chest",
        "labelTranslationId": "R_ANT005",
        "highlightRegion": "Chest",
        "lineAnchor": [507, 250]
      },
      {
        "viewId": "arms",
        "label": "Arm",
        "labelTranslationId": "R_ANT009",
        "highlightRegion": "Arm",
        "lineAnchor": {f: [598, 327], m: [625, 308]}
      },
      {
        "viewId": "hands",
        "label": "Hand",
        "labelTranslationId": "R_ANT054",
        "highlightRegion": "Hand",
        "lineAnchor": {f: [642, 500], m: [680, 520]}
      },
      {
        "viewId": "abdomen",
        "label": "Belly",
        "labelTranslationId": "R_ANT103",
        "highlightRegion": "Ab",
        "lineAnchor": [506, 375]
      },
      {
        "viewId": "pelvis",
        "label": "Hips & Pelvis",
        "labelTranslationId": "R_ANT063",
        "highlightRegion": "HipsAndPelvis",
        "lineAnchor": [507, 452]
      },
      {
        "viewId": "legs",
        "label": "Leg",
        "labelTranslationId": "R_ANT066",
        "highlightRegion": "Leg-Front",
        "lineAnchor": [544, 631]
      },
      {
        "viewId": "feet",
        "label": "Foot",
        "labelTranslationId": "R_ANT073",
        "highlightRegion": "Foot",
        "lineAnchor": {f: [529, 975], m: [560, 951]}
      },
      {
        "viewId": "back",
        "label": "Back",
        "labelTranslationId": "R_ANT024",
        "highlightRegion": "Back",
        // "lineAnchor": [569, 366]
      }
    ]
  },
  "head": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_head_{SEX}.svg",
    "allHighlightRegionId": "Head",
    "choices": [
      {
        "value": "Top of head",
        "label": "Top",
        "labelTranslationId": "R_ANT026",
        "highlightRegion": "Head-Top",
        "lineAnchor": {f: [518, 82], m: [500, 46]}
      },
      {
        "value": "Forehead",
        "label": "Forehead",
        "labelTranslationId": "R_ANT027",
        "highlightRegion": "Head-Forehead",
        "lineAnchor": {f: [398, 202], m: [509, 181]}
      },
      {
        "value": "Temple",
        "label": "Temple",
        "labelTranslationId": "R_ANT028",
        "highlightRegion": "Head-Temple",
        "lineAnchor": {f: [533, 249], m: [662, 252]}
      },
      {
        "value": "Eyes",
        "label": "Eye",
        "labelTranslationId": "R_ANT029",
        "highlightRegion": "Head-Eyes",
        "lineAnchor": {f: [430, 328], m: [606, 335]}
      },
      {
        "value": "Ear",
        "label": "Ear",
        "labelTranslationId": "R_ANT031",
        "highlightRegion": "Head-Ear",
        "lineAnchor": {f: [631, 361], m: [318, 377]}
      },
      {
        "value": "Nose",
        "label": "Nose",
        "labelTranslationId": "R_ANT030",
        "highlightRegion": "Head-Nose",
        "lineAnchor": {f: [378, 383], m: [532, 409]}
      },
      {
        "value": "Mouth",
        "label": "Mouth",
        "labelTranslationId": "R_ANT032",
        "highlightRegion": "Head-Mouth",
        "lineAnchor": {f: [392, 436], m: [522, 476]}
      },
      {
        "value": "Jaw",
        "label": "Jaw",
        "labelTranslationId": "R_ANT012",
        "highlightRegion": "Head-Jaw",
        "lineAnchor": {f: [489, 517], m: [600, 545]}
      },
      {
        "value": "Neck",
        "label": "Neck",
        "labelTranslationId": "R_ANT003",
        "highlightRegion": "Head-Neck",
        "lineAnchor": {f: [500, 637], m: [515, 624]}
      }
    ]
  },
  "chest": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_chest_{SEX}.svg",
    "allHighlightRegionId": "Chest",
    "choices": [
      {
        "value": "Collarbone",
        "label": "Collarbone",
        "labelTranslationId": "R_ANT033",
        "highlightRegion": "Chest-Collarbone",
        "lineAnchor": [566, 112]
      },
      {
        "value": "Upper Chest / Pectorals",
        "label": "Upper Chest",
        "labelTranslationId": "R_ANT034",
        "highlightRegion": "Chest-UpperChest",
        "lineAnchor": [508, 159]
      },
      {
        "value": "Sternum",
        "label": "Sternum",
        "labelTranslationId": "R_ANT037",
        "highlightRegion": "Chest-Sternum",
        "lineAnchor": [513, 216]
      },
      {
        "value": "Breasts",
        "label": "Breasts",
        "labelTranslationId": "R_ANT035",
        "highlightRegion": "Chest-Breasts",
        "onlyFemale": true,
        "lineAnchor": [580, 278]
      },
      {
        "value": "Ribs",
        "label": "Ribs",
        "labelTranslationId": "R_ANT036",
        "highlightRegion": "Chest-Ribs",
        "lineAnchor": [581, 340]
      },
      {
        "value": "Lower Chest",
        "label": "Lower Chest",
        "labelTranslationId": "R_ANT038",
        "highlightRegion": "Chest-LowerChest",
        "lineAnchor": [508, 316]
      },
      {
        "value": "Diaphragm",
        "label": "Diaphragm",
        "labelTranslationId": "R_PRG045",
        "highlightRegion": "Chest-Diaphragm",
        "lineAnchor": [508, 350]
      }
    ]
  },
  "abdomen": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_belly_{SEX}.svg",
    "allHighlightRegionId": "Ab",
    "choices": [
      {
        "value": "Upper Left",
        "label": "Upper Left",
        "labelTranslationId": "R_ANT113",
        "highlightRegion": "Ab-UpperLeft",
        "lineAnchor": [593, 304]
      },
      {
        "value": "Upper Center",
        "label": "Upper Center",
        "labelTranslationId": "R_ANT040", // R_ANT112
        "highlightRegion": "Ab-UpperCenter",
        "lineAnchor": [505, 301]
      },
      {
        "value": "Upper Right",
        "label": "Upper Right",
        "labelTranslationId": "R_ANT111",
        "highlightRegion": "Ab-UpperRight",
        "lineAnchor": [420, 307]
      },
      {
        "value": "Mid Left",
        "label": "Mid Left",
        "labelTranslationId": "R_ANT116",
        "highlightRegion": "Ab-MiddleLeft",
        "lineAnchor": [605, 433]
      },
      {
        "value": "Mid Center",
        "label": "Mid Center",
        "labelTranslationId": "R_ANT043", // R_ANT115
        "highlightRegion": "Ab-MiddleCenter",
        "lineAnchor": [505, 433]
      },
      {
        "value": "Mid Right",
        "label": "Mid Right",
        "labelTranslationId": "R_ANT114",
        "highlightRegion": "Ab-MiddleRight",
        "lineAnchor": [406, 433]
      },
      {
        "value": "Lower Left",
        "label": "Lower Left",
        "labelTranslationId": "R_ANT119",
        "highlightRegion": "Ab-LowerLeft",
        "lineAnchor": [617, 555]
      },
      {
        "value": "Lower Center",
        "label": "Lower Center",
        "labelTranslationId": "R_ANT046", // R_ANT118
        "highlightRegion": "Ab-LowerCenter",
        "lineAnchor": [505, 556]
      },
      {
        "value": "Lower Right",
        "label": "Lower Right",
        "labelTranslationId": "R_ANT117",
        "highlightRegion": "Ab-LowerRight",
        "lineAnchor": [393, 555]
      },
    ]
  },
  "back": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_back_{SEX}.svg",
    "allHighlightRegionId": "Back",
    "choices": [
      {
        "value": "Back of Head",
        "label": "Back of Head",
        "labelTranslationId": "R_ANT107",
        "highlightRegion": "Back-Head",
        "lineAnchor": {f: [509, -52], m: [500, 75]}
      },
      {
        "value": "Neck Muscles",
        "label": "Neck Muscles",
        "labelTranslationId": "R_ANT085",
        "highlightRegion": "Back-Neck",
        "lineAnchor": {f: [531, 134], m: [554, 174]}
      },
      {
        "value": "Cervical Vertebrae",
        "label": "Neck Bones",
        "labelTranslationId": "R_ANT064",
        "highlightRegion": "Back-NeckSpine",
        "lineAnchor": {f: [507, 150], m: [502, 179]}
      },
      {
        "value": "Thoracic Vertebrae",
        "label": "Back Bones",
        "labelTranslationId": "R_ANT089",
        "highlightRegion": "Back-Spine",
        "lineAnchor": [507, 367]
      },
      {
        "value": "Upper Back Muscles",
        "label": "Upper Back Muscles",
        "labelTranslationId": "R_ANT087",
        "highlightRegion": "Back-UpperBack",
        "lineAnchor": [544, 304]
      },
      {
        "value": "Middle Back Muscles",
        "label": "Middle Back Muscles",
        "labelTranslationId": "R_ANT091",
        "highlightRegion": "Back-MidBack",
        "lineAnchor": [544, 533]
      },
      {
        "value": "Lower Back Muscles",
        "label": "Lower Back Muscles",
        "labelTranslationId": "R_ANT093",
        "highlightRegion": "Back-LowBack",
        "lineAnchor": [544, 706]
      },
    ]
  },
  "pelvis": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_hips_pelvis_{SEX}.svg",
    "allHighlightRegionId": ["HipsAndPelvis", "HipsAndPelvis-Back"],
    "choices": [
      {
        "value": "Pelvis",
        "label": "Pelvis",
        "labelTranslationId": "R_ANT104",
        "highlightRegion": "HipsAndPelvis-Pelvis",
        "onlyFemale": true,
        "lineAnchor": [380, 212]
      },
      {
        "value": "Hips",
        "label": "Hips",
        "labelTranslationId": "R_ANT105",
        "highlightRegion": "HipsAndPelvis-Hips",
        "lineAnchor": {f: [455, 226], m: [436, 261]}
      },
      {
        "value": "Sacrum",
        "label": "Base of the spine",
        "labelTranslationId": "R_ANT097",
        "highlightRegion": "HipsAndPelvis-BaseOfTheSpine",
        "lineAnchor": {f: [636, 187], m: [660, 217]}
      },
      {
        "value": "Coccyx",
        "label": "Tailbone",
        "labelTranslationId": "R_ANT102",
        "highlightRegion": "HipsAndPelvis-Tailbone",
        "lineAnchor": {f: [636, 214], m: [660, 254]}
      },
      {
        "value": "Gluteal Muscles",
        "label": "Buttocks",
        "labelTranslationId": "R_ANT065",
        "highlightRegion": "HipsAndPelvis-Buttocks",
        "lineAnchor": {f: [587, 240], m: [617, 294]}
      },
      {
        "value": "Groin",
        "label": "Groin",
        "labelTranslationId": "R_ANT016",
        "highlightRegion": "HipsAndPelvis-Groin",
        "lineAnchor": {f: [402, 261], m: [371, 309]}
      },
      {
        "value": "Pubis",
        "label": "Pubis",
        "labelTranslationId": "R_ANT110",
        "highlightRegion": "HipsAndPelvis-Pubis",
        "onlyFemale": true,
        "lineAnchor": [378, 260]
      },
    ]
  },
  "arms": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_arm_{SEX}.svg",
    "allHighlightRegionId": "Arm",
    "choices": [
      {
        "value": "Shoulder",
        "label": "Shoulder",
        "labelTranslationId": "R_ANT049",
        "highlightRegion": "Arm-Shoulder",
        "lineAnchor": [492, 154]
      },
      {
        "value": "Bicep",
        "label": "Bicep",
        "labelTranslationId": "R_ANT050",
        "highlightRegion": "Arm-Bicep",
        "lineAnchor": [508, 293]
      },
      // Taken out in simplification pre-VW releast Oct 2024
      // {
      //   "value": "Triceps",
      //   "label": "Tricep",
      //   "labelTranslationId": "R_ANT051",
      //   "highlightRegion": ""
      // },
      // These aren't relevant now that the left vs right is merged
      // {
      //   "value": "Right arm",
      //   "label": "Right arm",
      //   "labelTranslationId": "R_ANT013",
      //   "highlightRegion": "Arm-Right"
      // },
      // {
      //   "value": "Left arm",
      //   "label": "Left arm",
      //   "labelTranslationId": "R_ANT014",
      //   "highlightRegion": "Arm-Left"
      // },
      {
        "value": "Elbow",
        "label": "Elbow",
        "labelTranslationId": "R_ANT052",
        "highlightRegion": "Arm-Elbow",
        "lineAnchor": [520, 406]
      },
      {
        "value": "Forearm",
        "label": "Forearm",
        "labelTranslationId": "R_ANT053",
        "highlightRegion": "Arm-Forearm",
        "lineAnchor": [548, 518]
      }
    ]
  },
  "hands": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_hand.svg",
    "allHighlightRegionId": "Hand_2",
    "choices": [
      {
        "value": "Palm",
        "label": "Palm",
        "labelTranslationId": "R_ANT056",
        "highlightRegion": "Hand-Palm",
        "lineAnchor": [483, 284]
      },
      {
        "value": "Wrist",
        "label": "Wrist",
        "labelTranslationId": "R_ANT055",
        "highlightRegion": "Hand-Wrist",
        "lineAnchor": [472, 390]
      },
      {
        "value": "Thumb",
        "label": "Thumb",
        "labelTranslationId": "R_ANT057",
        "highlightRegion": "Hand-Thumb",
        "lineAnchor": [630, 277]
      },
      {
        "value": "Index Finger",
        "label": "Index Finger",
        "labelTranslationId": "R_ANT058",
        "highlightRegion": "Hand-IndexFinger",
        "lineAnchor": [533, 141]
      },
      {
        "value": "Middle Finger",
        "label": "Middle Finger",
        "labelTranslationId": "R_ANT059",
        "highlightRegion": "Hand-MiddleFiner",
        "lineAnchor": [482, 125]
      },
      {
        "value": "Ring Finger",
        "label": "Ring Finger",
        "labelTranslationId": "R_ANT060",
        "highlightRegion": "Hand-RingFinger",
        "lineAnchor": [435, 144]
      },
      {
        "value": "Pinky Finger",
        "label": "Pinky Finger",
        "labelTranslationId": "R_ANT061",
        "highlightRegion": "Hand-PinkyFinger",
        "lineAnchor": [375, 197]
      },
      // {
      //   "value": "Back of Hand",
      //   "label": "Back of Hand",
      //   "labelTranslationId": "R_ANT062"
      // }
    ]
  },
  "legs": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_leg_{SEX}.svg",
    "allHighlightRegionId": ["Leg-Front", "Leg-Back"],
    "choices": [
      {
        "value": "Thighs",
        "label": "Thigh",
        "labelTranslationId": "R_ANT067",
        "highlightRegion": "Leg-Thigh",
        "lineAnchor": [421, 360]
      },
      // {
      //   "value": "Quadriceps",
      //   "label": "Quadricep",
      //   "labelTranslationId": "R_ANT068",
      //   "highlightRegion": "Thighs"
      // },
      {
        "value": "Hamstrings",
        "label": "Hamstring",
        "labelTranslationId": "R_ANT069",
        "highlightRegion": ["Leg-HamstringLeft", "Leg-HamstringRight"],
        "lineAnchor": [598, 363]
      },
      {
        "value": "Knees",
        "label": "Knee",
        "labelTranslationId": "R_ANT070",
        "highlightRegion": "Leg-Knee",
        "lineAnchor": [412, 497]
      },
      {
        "value": "Calves",
        "label": "Calve",
        "labelTranslationId": "R_ANT071",
        "highlightRegion": "Leg-Calve",
        "lineAnchor": [602, 571]
      },
      {
        "value": "Shin",
        "label": "Shin",
        "labelTranslationId": "R_ANT072",
        "highlightRegion": "Leg-LowerLeg",
        "lineAnchor": [404, 635]
      },
      {
        "value": "Ankles",
        "label": "Ankle",
        "labelTranslationId": "R_ANT074",
        "highlightRegion": "Leg-Ankle",
        "lineAnchor": [400, 708]
      },
    ]
  },
  "feet": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_foot.svg",
    "allHighlightRegionId": "Foot_2",
    "choices": [
      {
        "value": "Top of Foot",
        "label": "Top of Foot",
        "labelTranslationId": "R_ANT075",
        "highlightRegion": "Foot-Top",
        "lineAnchor": [469, 199]
      },
      {
        "value": "Achilles Tendon",
        "label": "Achilles Tendon",
        "labelTranslationId": "R_ANT076",
        "highlightRegion": "Foot-Achilles",
        "lineAnchor": [646, 137]
      },
      {
        "value": "Arch",
        "label": "Arch",
        "labelTranslationId": "R_ANT077",
        "highlightRegion": "Foot-Arch",
        "lineAnchor": [532, 242]
      },
      {
        "value": "Heel",
        "label": "Heel",
        "labelTranslationId": "R_ANT128",
        "highlightRegion": "Foot-Heel",
        "lineAnchor": [637, 334]
      },
      {
        "value": "Side of Foot",
        "label": "Side of Foot",
        "labelTranslationId": "R_ANT126",
        "highlightRegion": "Foot-Side",
        "lineAnchor": [559, 393]
      },
      {
        "value": "Ball",
        "label": "Ball",
        "labelTranslationId": "R_ANT078",
        "highlightRegion": "Foot-Ball",
        "lineAnchor": [436, 363]
      },
      {
        "value": "Big toe",
        "label": "Big toe",
        "labelTranslationId": "R_ANT079",
        "highlightRegion": "Foot-BigToe",
        "lineAnchor": [366, 320]
      },
      {
        "value": "Second toe",
        "label": "Second toe",
        "labelTranslationId": "R_ANT080",
        "highlightRegion": "Foot-SecondToe",
        "lineAnchor": [372, 349]
      },
      {
        "value": "Middle toe",
        "label": "Middle toe",
        "labelTranslationId": "R_ANT081",
        "highlightRegion": "Foot-MiddleToe",
        "lineAnchor": [383, 372]
      },
      {
        "value": "Fore toe",
        "label": "Fore toe",
        "labelTranslationId": "R_ANT082",
        "highlightRegion": "Foot-ForeToe",
        "lineAnchor": [403, 394]
      },
      {
        "value": "Pinky toe",
        "label": "Pinky toe",
        "labelTranslationId": "R_ANT083",
        "highlightRegion": "Foot-PinkyToe",
        "lineAnchor": [429, 410]
      }
    ]
  }
}
/*
let modelToSheetExport = "";
Object.entries(DEFAULT_HUMAN_MODEL_V2).forEach(([id, view], index) => {
  const viewData = [
    id,
    !!view.isRoot,
    !!view.isLeaf,
    view.imgUrl ?? ""
  ];
  modelToSheetExport += viewData.join("\t");
  view.choices.forEach((c, i) => {
    if (i > 0) {
      modelToSheetExport += viewData.map(x => "").join("\t");
    }
    const choiceData = [
      i,
      c.viewId,
      c.value,
      c.label,
      c.labelTranslationId,
      c.highlightRegion,
      c.lineAnchor ? JSON.stringify(c.lineAnchor) : ""
    ];
    modelToSheetExport += "\t" + choiceData.join("\t") + "\n";
  })
  if (view.choices.length === 0) {
    modelToSheetExport += "\n";
  }
});
console.warn(modelToSheetExport);
*/

const EmptyModel : AnatomicalModel = {"error": {imgUrl: "--", choices: [], isLeaf: true}};

export const PredefinedModels : Record<string, AnatomicalModel> = {
  "default_human": DEFAULT_HUMAN_MODEL_V2,
  "EMPTY": EmptyModel
};
