import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultAudioSequence } from "../../../constants/ui-config";
import { Answer } from "../../../models/answers";
import { AudioLocation, getAudioLocationFromIdOrNull, KeyTranslationIDs } from "../../../models/audio";
import { CoreDataType } from "../../../models/core-data-types";
import { MetricsEvent } from "../../../models/metrics";
import { Page, PageType } from "../../../models/pages";
import { AudioSequence, audioSlice, requestOrRegister } from "../../../store/slices/audio";
import { metricsSlice } from "../../../store/slices/metrics";
import { safeStringify } from "../../../utils";
import { IDed } from "../../../utils/database";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { CONSENT_MODAL, KNOWN_PAGE_TYPES } from "../constants_to_move";
import { SubpageCount } from "../QuestionnaireFlow";
import { DebugPage } from "./DebugPage";
import { LanguageSelectorPage } from "./LanguageSelector";
import { LoadingPage } from "./LoadingPage";
import { QuestionPage } from "./QuestionPage";
import { RedesignedReport } from "./RedesignedReport";
import { SaveWaypointPage } from "./SaveWaypointPage";
import { SuccessPage } from "./SuccessPage";
import { TitlePage } from "./TitlePage";

export function StandardPage ({
    questionnaire,
    answers,
    activeQuestionId,
    currentPage,
    pageNumber,
    subpage,
    questionHidden,
    hiddenChoices,
    // actions
    completeQuestion,
    navigateBack,
    acceptAnswer,
    restart,
    setFullPageModal
  }: {
    questionnaire: any,
    answers: any,
    activeQuestionId: string,
    currentPage: Page,
    pageNumber: number,
    subpage: SubpageCount,
    questionHidden: Record<string, boolean>,
    hiddenChoices: string[],
    // actions
    completeQuestion: () => void,
    navigateBack: () => void,
    acceptAnswer: (newAnswer: Answer<any>, key: string) => void,
    restart: () => boolean,
    setFullPageModal: (modalDef: any) => void
  }) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const autoplayAudio = useAppSelector(s => s.audio.autoplay);

  function showConsentModal () {
    setFullPageModal_Inner({
      type: "markdown",
      title: "Consent Form",
      contentURL: "content/forms/2024-002_consent_{LANG}.md"
    });
    // TODO: this should be a generic feature of the consent modal
    if (autoplayAudio) {
      const consentAudioID = KeyTranslationIDs.ConsentFormWhole;
      const consentAudioLocation = getAudioLocationFromIdOrNull(consentAudioID)!!;
      requestOrRegister([consentAudioLocation], dispatch);
      dispatch(audioSlice.actions.playKnownAudio(consentAudioLocation.filename));
    }
  }

  function recordConsent () {
    dispatch(metricsSlice.actions.recordEvent({type: MetricsEvent.KioskProvideConsent}));
    acceptAnswer({isMulti: false, coreType: CoreDataType.Timestamp, value: {value: Date.now()}}, "_consentTime");
    completeQuestion();
  }

  function setFullPageModal_Inner (o: any) {
    setFullPageModal(o);
  }

  /**
   * Create an AudioSequence to narrate the elements of this page
   */
  function buildAudioLocationList () : AudioLocation[] {
    const locations: Array<AudioLocation|null> = [];

    switch (currentPage.type) {
      case PageType.Title:
      case PageType.KioskConsent:
      case PageType.KioskStart:
        locations.push(getAudioLocationFromIdOrNull(currentPage.titleTranslationId));
        locations.push(getAudioLocationFromIdOrNull(currentPage.descriptionTranslationId));
        locations.push(getAudioLocationFromIdOrNull(currentPage.textChildTranslationId));
        locations.push(getAudioLocationFromIdOrNull(currentPage.callToActionTranslationId));
        break;
      case PageType.Success:
        locations.push(getAudioLocationFromIdOrNull(currentPage.titleTranslationId ?? KeyTranslationIDs.Success_Title));
        locations.push(getAudioLocationFromIdOrNull(currentPage.descriptionTranslationId ?? KeyTranslationIDs.Success_Description));
        locations.push(getAudioLocationFromIdOrNull(currentPage.feedbackPromptTranslationId ?? KeyTranslationIDs.Success_FeedbackPrompt));
        if (Array.isArray(currentPage.feedbackChoices)) {
          currentPage.feedbackChoices.forEach(choice => {
            locations.push(getAudioLocationFromIdOrNull(choice.titleTranslationId));
          });
        } else {
          [
            KeyTranslationIDs.Success_FeedbackSatisfied,
            KeyTranslationIDs.Success_FeedbackNeutral,
            KeyTranslationIDs.Success_FeedbackDissatisfied,
          ].forEach(tID => locations.push(getAudioLocationFromIdOrNull(tID)));
        }
        break
      default:
        break;
    }

    locations.push()

    // submit
    return locations.filter(l => l !== null);
  }
  
  /** Queue up the AudioSequence for the page */
  useEffect(() => {
    if (!autoplayAudio) return;
    if (currentPage && currentPage.type !== PageType.Question) {
      // Standard question audio is currently handled in QuestionPage; this is
      // solely for other page types.
      const locs = buildAudioLocationList();
      if (locs.length > 0) {
        requestOrRegister(locs, dispatch);
        const sequence: Partial<AudioSequence> & IDed = {
          ...DefaultAudioSequence,
          id: `page-${currentPage.type}-${pageNumber}`,
          files: locs.map(l => l.filename)
        };
        dispatch(audioSlice.actions.playSequence(sequence));
      }
    }
  }, [currentPage, autoplayAudio]);

  const knownPageTypes = Object.values(PageType);
  if (!knownPageTypes.includes(currentPage.type)) {
    return (
      <div
        className="centered-flex-container main-columm force-full-height"
        style={{ justifyContent: "center" }}
      >
        <h3 className="text-center text-warning">
          Unknown Page Type: {`${currentPage.type}`}
        </h3>
        <pre
          className="p-2 mb-0"
          style={{
            whiteSpace: "pre-wrap",
            background: "#E0E0FF",
            borderRadius: "8px",
          }}
        >
          {safeStringify(currentPage)}
        </pre>
      </div>
    );
  }

  switch (currentPage.type) {
    case PageType.Loading:
      console.log(currentPage, questionnaire?.pages);
      return <LoadingPage />;
    case PageType.Error:
      return (
        <div
          className="centered-flex-container force-full-height main-columm"
          style={{justifyContent: "center" }}
        >
          <h3 className="text-center text-warning">{currentPage.error}</h3>
          {currentPage.trace ? (
            <pre
              className="p-2 mb-0"
              style={{
                whiteSpace: "pre-wrap",
                background: "#E0E0FF",
                borderRadius: "8px",
              }}
            >
              {currentPage.trace}
            </pre>
          ) : null}
        </div>
      );
    case PageType.Debug:
      return <DebugPage {...currentPage} advance={completeQuestion} />;
    case PageType.Title:
      return <TitlePage {...currentPage} actOnCTA={completeQuestion} />;
    case PageType.KioskStart:
      return (
        <TitlePage
          {...currentPage}
          callToAction="Start"
          actOnCTA={completeQuestion}
        />
      );
    case PageType.KioskConsent:
      return (
        <TitlePage {...currentPage} actOnCTA={recordConsent}>
          <a
            onClick={showConsentModal}
            style={{textDecoration: "underline"}}
          >
            {t("P_GEN020", "Learn more about the study")}
          </a>
        </TitlePage>
      );
    case PageType.LanguageSelector:
      return <LanguageSelectorPage />;
    case PageType.Review:
      return (
        <div
          className="centered-flex-container main-column force-full-height"
          style={{justifyContent: "center", paddingBottom: "75px" }}
        >
          <RedesignedReport
            questionnaireDefinition={questionnaire}
            answers={answers}
            user={{ name: "Prototype User" }}
            acceptAnswer={acceptAnswer}
            {...currentPage}
          />
        </div>
      );
    case PageType.SaveWaypoint:
      return <SaveWaypointPage {...currentPage} advance={completeQuestion} />;
    case PageType.Success:
      return (
        <div
          className="centered-flex-container main-column full-height"
          style={{
            justifyContent: "space-between",
            paddingBottom: 0
          }}
        >
          <hr style={{ opacity: "0%", margin: 0 }} />
          <SuccessPage restartAction={restart} />
        </div>
      );
    case PageType.Question:
      return (
        <QuestionPage
          {...currentPage}
          questionnaire={questionnaire}
          answers={answers}
          activeQuestionId={activeQuestionId}
          subpage={subpage}
          questionHidden={questionHidden}
          hiddenChoices={hiddenChoices}
          openModal={setFullPageModal_Inner}
          acceptAnswer={acceptAnswer}
          completeQuestion={completeQuestion}
          navigateBack={navigateBack}
        />
      );
    case PageType.HybridQuestionSummary:
    case PageType.UniqueQuestion:
      // do nothing, these are not yet standardized and have custom blocks in
      // QuestionnaireFlow until better handled
      return null;
    default:
      // treat as unknown page type! but means we have a mistake in the code here...
      console.error(
        `Page type has no display case, but listed in KNOWN_TYPES: ${currentPage.type}`
      );
      return (
        <div
          className="centered-flex-container main-columm force-full-height"
          style={{ justifyContent: "center" }}
        >
          <h3 className="text-center text-warning">
            Unknown Page Type: {`${currentPage.type}`}
          </h3>
          <pre
            className="p-2 mb-0"
            style={{
              whiteSpace: "pre-wrap",
              background: "#E0E0FF",
              borderRadius: "8px",
            }}
          >
            {safeStringify(currentPage)}
          </pre>
        </div>
      );
  }
}